import React from 'react';
import PostsGrid from './PostsGrid';
import { ConnectedItems } from '../types';

type Props = {
  relatedPosts: ConnectedItems[];
};

const RelatedPosts = ({ relatedPosts }: Props) => {
  return <PostsGrid posts={relatedPosts} gridTrack="auto-fit" />;
};

export default RelatedPosts;
