import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import { ShareOptions } from 'shared/utils/social/types';
import { Box, DisplayText, Grid } from 'shared/components';
import { colors, mediaQuery } from 'shared/utils';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { SharePost } from '../components/sharePost';
import { Site, Post, ConnectedItems } from '../types';
import RelatedPosts from '../components/relatedPosts';

type Props = {
  data: {
    site: Site;
    postsBySlug: Post;
    relatedPosts: {
      edges: ConnectedItems[];
    };
  };
  pageContext: {
    tags: string[];
    slug: string;
    previous: Post;
    next: Post;
  };
  location: Location;
};

const networksToShare: ShareOptions[] = [
  ShareOptions.Facebook,
  ShareOptions.LinkedIn,
  ShareOptions.Twitter,
  ShareOptions.Email,
];

const BlogPostTemplate = ({ data, location }: Props) => {
  const { postsBySlug: post, relatedPosts } = data;
  const { title: siteTitle, siteUrl } = data.site.siteMetadata;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.featuredImage?.childImageSharp.fixed.src}
      />

      <Box as="article" mt={3}>
        <header>
          <Grid gridTemplateColumns={[null, '1fr 1fr 1fr']} m={6}>
            <time>{post.frontmatter.date}</time>
            <span>{post.frontmatter.authors.join(', ')}</span>
            <span css={{ justifySelf: 'flex-end', textTransform: 'capitalize' }}>
              {post.frontmatter.tags?.join(', ')}
            </span>
          </Grid>
          <DisplayText mb={6} mx="auto" px={6} fontWeight="medium" css={{ maxWidth: 675 }}>
            {post.frontmatter.title}
          </DisplayText>
        </header>

        <Box as="section" maxWidth={675} mt={8} mx="auto" px={6}>
          <div
            css={sx.article}
            dangerouslySetInnerHTML={{
              __html: post.html,
            }}
          />
        </Box>
      </Box>

      <SharePost
        url={siteUrl + post.fields.slug}
        title={post.frontmatter.title}
        networks={networksToShare}
      />

      <hr style={{ marginTop: 48, marginBottom: 32, color: colors.gray }} />

      <RelatedPosts relatedPosts={relatedPosts.edges} />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    postsBySlug: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        tags
        authors
        date(formatString: "MM DD YY")
        description
        featuredImage {
          childImageSharp {
            fixed(width: 800, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      limit: 4
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { published: { eq: true }, fields: { slug: { ne: $slug } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            authors
            date(formatString: "MM DD YY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const sx = {
  // need to use nested css here since we don't have control of remark's html
  // that's returned as a string
  article: css`
    p {
      font-size: 18px;
    }

    /* indicator for end of article */
    > p:last-child::after {
      content: '◼'; /* Glpyh 457 from VSCO Gothic "Black Medium Square" */
      display: block;
      margin-top: 32px;
    }

    > h1,
    > h2 {
      margin-top: 64px;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-top: 32px;
    }

    blockquote {
      margin: 48px;
    }

    img + em,
    .gatsby-resp-image-wrapper + em,
    .gatsby-highlight + p > em {
      display: block;
      max-width: 440px;
      margin-top: 8px;
      margin-left: auto;
      margin-right: auto;
      font-size: 14px;
      text-align: center;
    }

    a {
      color: ${colors.gold};
      transition: color 0.2s ease-in-out;

      &:hover,
      &:focus {
        color: ${colors.lightGold};
      }
    }

    code,
    pre {
      font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
    }

    .gatsby-highlight {
      overflow-x: auto;
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      background: ${colors.offWhite};
      color: ${colors.black};
      tab-size: 1.5em;

      ${mediaQuery(675, 'max-width')} {
        width: 100vw;
        margin-left: -32px;
        margin-right: -32px;
      }
    }

    .gatsby-highlight > pre,
    .gatsby-highlight > code {
      height: auto;
      margin: 1rem;
      font-size: 14px;
      line-height: 20px;
      white-space: pre;
      word-break: break-word;
    }

    .line-numbers .line-numbers-rows {
      border-right: none;
      color: ${colors.grayDark};
    }

    .gatsby-highlight pre[class*='language-'].line-numbers {
      padding-left: 32px;
    }

    .token.keyword {
      color: ${colors.red};
    }

    .token.comment {
      color: ${colors.gold};
    }

    .token.string,
    .token.token.punctuation {
      color: ${colors.charcoal};
    }

    .token.operator,
    .token.entity,
    .token.url,
    .token.variable {
      color: ${colors.grayDark};
    }

    .token.function {
      color: ${colors.blue};
    }

    .token.atrule,
    .token.class-name {
      color: ${colors.orange};
    }

    .token.property,
    .token.number,
    .token.function-name,
    .token.constant,
    .token.symbol,
    .token.deleted {
      color: ${colors.royalBlue};
    }

    .token.tag {
      color: ${colors.darkPink};
    }
  `,
};
