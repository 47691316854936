import React from 'react';
import { getSocialShareHandler } from 'shared/utils/social/socialShare';
import { ShareOptions } from 'shared/utils/social/types';
import { colors } from 'shared/utils/colors';
import { Box, HeadingText } from 'shared/components';

type Props = {
  url: string;
  networks: ShareOptions[];
  title?: string;
  summary?: string;
};

export const SharePost = ({ url, title, networks, summary }: Props) => {
  return (
    <Box id="share-post" display="flex" flexDirection="column" alignItems="center" my={8}>
      <HeadingText
        fontSize={2}
        mb={3}
        color="charcoalDark"
        css={{ textTransform: 'uppercase', letterSpacing: 1 }}
      >
        Share this post
      </HeadingText>
      <div
        css={{
          display: 'flex',
          width: '100%',
          maxWidth: 280,
          justifyContent: 'space-between',
          alignContent: 'center',
          margin: '0 auto',
        }}
      >
        {networks.map((network, idx) => {
          return (
            <div key={network}>
              <button
                onClick={getSocialShareHandler({ url, title, network, summary })}
                css={{ color: colors.gold }}
              >
                {ShareOptions[network]}
              </button>
              {idx < networks.length - 1 && ' | '}
            </div>
          );
        })}
      </div>
    </Box>
  );
};
