import { ShareOptions, NetworkUrls, SocialOpts } from './types';

type Args = {
  url: string;
  network: ShareOptions;
  title?: string;
  summary?: string;
};

export const getSocialShareHandler = ({ url, network, title, summary }: Args) => {
  const windowFeatures = 'width=400, height=400';
  const socialUrl = getNetworkUrl(network);
  const optsObj = getOptions({ network, url, title, summary });
  const link = buildEncodedShareUrl(socialUrl, optsObj);
  return () => {
    window.open(link, 'sharing', windowFeatures);
  };
};

function buildEncodedShareUrl(networkUrl: NetworkUrls, opts: SocialOpts) {
  const queryParamsString = convertSocialOptionsToQueryParams(opts);
  return `${networkUrl}${queryParamsString}`;
}

function convertSocialOptionsToQueryParams(opts: {
  [key: string]: string | number | boolean | undefined | null;
}) {
  const params = Object.entries(opts)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
}

function getOptions({ network, url, title, summary }: Args): SocialOpts {
  switch (network) {
    case ShareOptions.LinkedIn:
      return { url, title, summary, mini: true };
    case ShareOptions.Facebook:
      return { u: url, quote: summary, hashtag: title };
    case ShareOptions.Twitter:
      return { url, text: summary };
    case ShareOptions.Pinterest:
      return { url, description: summary };
    case ShareOptions.Email:
      return { subject: url, body: summary };
  }
}

function getNetworkUrl(network: ShareOptions) {
  switch (network) {
    case ShareOptions.LinkedIn:
      return NetworkUrls.LinkedIn;
    case ShareOptions.Facebook:
      return NetworkUrls.Facebook;
    case ShareOptions.Twitter:
      return NetworkUrls.Twitter;
    case ShareOptions.Pinterest:
      return NetworkUrls.Pinterest;
    case ShareOptions.Email:
      return NetworkUrls.Email;
  }
}
